$(document).ready(function() {
  AOS.init();

function removeLoader(){
  $( "#loading" ).fadeOut(1000, function() {
    $( "#loading-image" ).remove();
});  
}
function loadSite() {
  removeLoader();
  $( "#loading-image" ).remove();
}
loadSite();
jQuery(".slide__wrapper img").addClass("active");
jQuery(".slide__wrapper .theme__title").addClass("active");

jQuery(".tab__one").addClass("active");
jQuery("#tab1").addClass("active");

jQuery("#tab1").click(function() {
  jQuery(this).addClass("active");
  jQuery(this).siblings().removeClass("active");
  jQuery(".tab__one").addClass("active");
  jQuery(".tab__two").removeClass("active");
  jQuery(".tab__three").removeClass("active");
})

jQuery("#tab2").click(function() {
  jQuery(this).addClass("active");
  jQuery(this).siblings().removeClass("active");
  jQuery(".tab__two").addClass("active");
  jQuery(".tab__one").removeClass("active");
  jQuery(".tab__three").removeClass("active");
})

jQuery("#tab3").click(function() {
  jQuery(this).addClass("active");
  jQuery(this).siblings().removeClass("active");
  jQuery(".tab__three").addClass("active");
  jQuery(".tab__one").removeClass("active");
  jQuery(".tab__two").removeClass("active");
})

// slick slider start
$('.header__wrapper').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  speed: 2000,
  arrows: true,
  fade: true,
  cssEase: 'ease-in-out',
  responsive: [
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true
      }
    }
  ]
});

$('.product__box__wrapper').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  centerPadding: '60px',
  autoplay: true,
  autoplaySpeed: 2500,
  speed: 500,
  arrows: true,
  dots: false,
  cssEase: 'ease-in-out',
  responsive: [
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false
      }
    },
    {
      breakpoint: 786,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: true,
        arrows: false
      }
    },
    {
      breakpoint: 1008,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: true,
        arrows: false
      }
    }

  ]
});

$('.goodfood__box__wrapper').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2500,
  speed: 500,
  arrows: true,
  dots: false,
  cssEase: 'ease-in-out',
  responsive: [
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false
      }
    },
    {
      breakpoint: 786,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: true,
        arrows: false
      }
    },
    {
      breakpoint: 1008,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: true,
        arrows: false
      }
    }

  ]
});

})

jQuery(".mobile-menu").click(function() {
  jQuery(".mobile-menu").addClass("active");
  jQuery(".sidenav-list-wrapper").addClass("active");
  jQuery(".close__icon__mobile").addClass("active");
  jQuery("body").addClass("active__fixed");
  jQuery("html").addClass("active__fixed");
})

jQuery(".close__icon__mobile").click(function() {
  jQuery(".mobile-menu").removeClass("active");
  jQuery(".sidenav-list-wrapper").removeClass("active");
  jQuery(this).removeClass("active");
  jQuery("body").removeClass("active__fixed");
  jQuery("html").removeClass("active__fixed");
})
jQuery(".sidenav-list__items").click(function() {
  jQuery(".mobile-menu").removeClass("active");
  jQuery(".sidenav-list-wrapper").removeClass("active");
  jQuery(".close__icon__mobile").removeClass("active");
  jQuery("body").removeClass("active__fixed");
  jQuery("html").removeClass("active__fixed");
})



var tabsFn = (function() {
  
  function init() {
    setHeight();
  }
  
  function setHeight() {
    var $tabPane = $('.tab-pane'),
        tabsHeight = $('.nav-tabs').height();
    
    $tabPane.css({
      height: tabsHeight
    });
  }
    
  $(init);
})();


jQuery(".nav-tabs li").click(function() {
  jQuery(this).addClass("active");
  jQuery(this).siblings().removeClass("active");
})


$(window).scroll(function() {
  if ($(document).scrollTop() < 200) {
    $(".nav__logo").removeClass("active");
    $(".nav__items").removeClass("active");
  } else {
    $(".nav__logo").addClass("active");
    $(".nav__items").addClass("active");
  }
});
if ($(window).width() < 576) {
  if ($(document).scrollTop() == 0) {
    $(".nav__logo").removeClass("active");
    $(".nav__items").removeClass("active");
  } else {
    $(".nav__logo").addClass("active");
    $(".nav__items").addClass("active");
  }
}


